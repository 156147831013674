'use client';
import dynamic from 'next/dynamic';
import { ComponentType, FC } from 'react';
import { components } from '@/types/strapi';

interface DynamicZoneManagerProps {
	dynamicZone: NonNullable<components['schemas']['Page']['dynamic_zone']>;
	locale: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentMapping: Record<string, ComponentType<any>> = {
	'dynamic-zone.hero': dynamic(
		() => import('./Hero/Hero').then(mod => mod.Hero),
		{
			ssr: true,
		},
	),
	'dynamic-zone.success-metrics': dynamic(
		() =>
			import('./SuccessMetrics/SuccessMetrics').then(
				mod => mod.SuccessMetrics,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.logo-grid': dynamic(
		() => import('./LogoGrid/LogoGrid').then(mod => mod.LogoGrid),
		{ ssr: false },
	),
	'dynamic-zone.solutions-grid': dynamic(
		() =>
			import('./AnimatedFeatureGrid/AnimatedFeatureGrid').then(
				mod => mod.AnimatedFeatureGrid,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.case-studies': dynamic(
		() =>
			import('./CaseStudiesSection/CaseStudiesSection').then(
				mod => mod.CaseStudiesSection,
			),
		{
			ssr: true,
		},
	),
	'dynamic-zone.testimonials': dynamic(
		() =>
			import('./TestimonialsSection/TestimonialsSection').then(
				mod => mod.TestimonialsSection,
			),
		{
			ssr: true,
		},
	),
	'dynamic-zone.pricing': dynamic(
		() =>
			import('./PricingComparisonSlider/PricingComparisonSlider').then(
				mod => mod.PricingComparisonSlider,
			),
		{ ssr: false },
	),
	'dynamic-zone.faq': dynamic(
		() => import('./FAQSection/FAQSection').then(mod => mod.FAQSection),
		{ ssr: false },
	),
	'dynamic-zone.contact': dynamic(
		() =>
			import('./ContactSection/ContactSection').then(
				mod => mod.ContactSection,
			),
		{ ssr: false },
	),
	'dynamic-zone.related-articles': dynamic(
		() => import('./related-articles').then(mod => mod.RelatedArticles),
		{
			ssr: false,
		},
	),
	'dynamic-zone.simple-hero': dynamic(
		() => import('./SimpleHero/SimpleHero').then(mod => mod.SimpleHero),
		{ ssr: true },
	),
	'dynamic-zone.animated-feature-grid': dynamic(
		() =>
			import('./AnimatedFeatureGrid/AnimatedFeatureGrid').then(
				mod => mod.AnimatedFeatureGrid,
			),
		{ ssr: false },
	),

	'dynamic-zone.mission-vision': dynamic(
		() =>
			import('./CoreValuesBlock/CoreValuesBlock').then(
				mod => mod.CoreValuesBlock,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.leadership': dynamic(
		() =>
			import('./LeadershipSection/LeadershipSection').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.why-choose-section': dynamic(
		() =>
			import('./WhyChooseSection/WhyChooseSection').then(
				mod => mod.WhyChooseSection,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.cta-section': dynamic(
		() => import('./CTASection/CTASection').then(mod => mod.CTASection),
		{ ssr: false },
	),
	'dynamic-zone.partnership-types': dynamic(
		() =>
			import('./PartnershipTypes/PartnershipTypes').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.partnership-benefits': dynamic(
		() =>
			import('./PartnershipBenefits/PartnershipBenefits').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.grid-icon-features': dynamic(
		() =>
			import('./GridIconFeatures/GridIconFeatures').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.content-section': dynamic(
		() =>
			import('./ContentSection/ContentSection').then(
				mod => mod.ContentSection,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.two-column-cta': dynamic(
		() => import('./TwoColumnCTA/TwoColumnCTA').then(mod => mod.default),
		{ ssr: false },
	),
	'dynamic-zone.two-column-features': dynamic(
		() =>
			import('./TwoColumnFeatures/TwoColumnFeatures').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.two-column-message-preview': dynamic(
		() =>
			import('./TwoColumnMessagePreview/TwoColumnMessagePreview').then(
				mod => mod.default,
			),
		{ ssr: false },
	),
	'dynamic-zone.two-column-feature-grid': dynamic(
		() =>
			import('./TwoColumnFeatureGrid/TwoColumnFeatureGrid').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.two-column-icon-features': dynamic(
		() =>
			import('./TwoColumnIconFeatures/TwoColumnIconFeatures').then(
				mod => mod.default,
			),
		{
			ssr: false,
		},
	),
	'dynamic-zone.two-column-with-accordion': dynamic(
		() =>
			import('./TwoColumnWithAccordion/TwoColumnWithAccordion').then(
				mod => mod.default,
			),
		{ ssr: false },
	),
	'dynamic-zone.tutorial-video': dynamic(
		() => import('./TutorialVideo/TutorialVideo').then(mod => mod.default),
		{ ssr: false },
	),
	'dynamic-zone.messaging-solutions-grid': dynamic(
		() =>
			import('./MessagingSolutionsGrid/MessagingSolutionsGrid').then(
				mod => mod.default,
			),
		{ ssr: false },
	),
	'dynamic-zone.dlt-registration': dynamic(
		() =>
			import('./DLTRegistration/DLTRegistration').then(
				mod => mod.default,
			),
		{ ssr: false },
	),
	'dynamic-zone.strip-image-cta': dynamic(
		() => import('./StripImageCTA/StripImageCTA').then(mod => mod.default),
		{ ssr: false },
	),
	'dynamic-zone.heading-cta': dynamic(
		() => import('./HeadingCTA/HeadingCTA').then(mod => mod.HeadingCTA),
		{ ssr: false },
	),
	'dynamic-zone.g-map': dynamic(
		() => import('./GMap/GMap').then(mod => mod.default),
		{ ssr: false },
	),
	'dynamic-zone.image-carousel': dynamic(
		() =>
			import('./ImageCarousel/ImageCarousel').then(
				mod => mod.ImageCarousel,
			),
		{ ssr: false },
	),
	'dynamic-zone.event-video': dynamic(
		() => import('./EventVideo/EventVideo').then(mod => mod.default),
		{ ssr: false },
	),
	'dynamic-zone.feature-grid': dynamic(
		() => import('./FeatureGrid/FeatureGrid').then(mod => mod.FeatureGrid),
		{ ssr: false },
	),
	'dynamic-zone.job-openings': dynamic(
		() => import('./JobOpenings/JobOpenings').then(mod => mod.JobOpenings),
		{ ssr: false },
	),
	'dynamic-zone.feature-blocks-section': dynamic(
		() =>
			import('./FeatureBlocks/FeatureBlocks').then(
				mod => mod.FeatureBlocks,
			),
		{ ssr: false },
	),
	'dynamic-zone.blogs-resources': dynamic(
		() =>
			import('./BlogsResources/BlogsResources').then(mod => mod.default),
		{ ssr: false },
	),
	'dynamic-zone.api-documentation': dynamic(
		() =>
			import('./ApiDocumentation/ApiDocumentation').then(
				mod => mod.ApiDocumentation,
			),
		{ ssr: false },
	),
	'dynamic-zone.dynamic-table': dynamic(
		() => import('./Table/Table').then(mod => mod.Table),
		{ ssr: false },
	),
	'dynamic-zone.future-of-communication': dynamic(
		() =>
			import('./FutureOfCommunications/FutureOfCommunications').then(
				mod => mod.default,
			),
		{ ssr: false },
	),
};

const DynamicZoneManager: FC<DynamicZoneManagerProps> = ({
	dynamicZone,
	locale,
}) => {
	return (
		<div>
			{dynamicZone.map((componentData, index) => {
				const Component =
					componentMapping[
						componentData.__component as keyof typeof componentMapping
					];
				if (!Component) {
					console.warn(
						`No component found for: ${componentData.__component}`,
					);
					return null;
				}
				const uniqueKey = `${componentData.__component}-${componentData.id}-${index}`;
				return (
					<Component
						key={uniqueKey}
						{...componentData}
						locale={locale}
					/>
				);
			})}
		</div>
	);
};

export default DynamicZoneManager;
